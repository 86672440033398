import APIService from '@/services/api.service';

class Payment {
    
    create(params){
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.paypal.com/sdk/js?${params}`;
        return script
    }

    checkout(comp, providers, currency, callback){

        providers.forEach(provider => {
            const payment = `render${provider.type}Checkout`;
            this[payment](comp, provider, currency, callback);
        });
       
    }

    subscription(comp, providers, payload, callback, host = 'getApiHost'){

        providers.forEach(provider => {
            const payment = `render${provider.type}Subscription`;
            this[payment](comp, provider, payload, callback, host);
        });
        
    }

    renderPaypalSubscription(comp, provider, payload, callback, host){

        const script = this.create(`client-id=${provider.client_id}&vault=true&intent=subscription`);
        script.onload = function () {
            paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'black',
                    layout: 'vertical',
                    label: 'pay'
                },
                createSubscription: function(data, actions) {
                    return actions.subscription.create({
                        plan_id: payload.plan.paypal,
                    });
                },
                onApprove: function(data, actions) {

                    payload.subscription_id = data.subscriptionID;
                    payload.provider = provider.id;
                    payload.plan = payload.plan.id;
                   
                    comp.$store.dispatch('post', { endpoint: '/api/v1/subscriptions', event: payload, message: false, host: host})
                    .then((subscription) => {

                        if( callback.length > 0 && typeof(APIService[callback[0]])=='function' ){
                            APIService[callback[0]](callback[1]);
                        }
                        else{
                            APIService.setResponse({message: "Die Zahlung war erfolgreich!", color: "green"});
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        APIService.setResponse({message: 'Der Administrator darf keine Zahlung durchführen', color: "red"});
                    })
                }
            }).render('#paypal-subscription-container'); // Renders the PayPal button
        };

		document.head.appendChild(script);
    }


    renderPaypalCheckout(comp, provider, currency, callback){

        const script = this.create(`client-id=${provider.client_id}&currency=${currency}`);

		script.onload = function () {
			paypal.Buttons({
				style: {
					shape: 'rect',
					color: 'black',
					layout: 'vertical',
					label: 'pay'
				},
				createOrder() {
					return comp.$store.dispatch('post', { endpoint: '/api/v1/orders', event: {provider: provider.id}, message: false})
					.then((order) => order.result.id);
				},
				onApprove(data) {

					return comp.$store.dispatch('post', { endpoint: '/api/v1/orders/'+ data.orderID +'/capture', event: {provider: provider.id}, message: false})
					.then((orderData) => {
						comp.$store.dispatch('fetch', { endpoint: `/api/v1/users/me` })
						comp.$store.dispatch('delete',{ endpoint: `/api/v1/cart/`, message: false })
                        if( callback.length > 0 && typeof(APIService[callback[0]])=='function' ){
                            APIService[callback[0]](callback[1]);
                        }
                        else{
                            APIService.setResponse({message: "Vielen Dank für deinen Einkauf!", color: "green"});
                        }
					}).catch((error) => {
						console.log(error);
						APIService.setResponse({message: 'Der Administrator darf keine Zahlung durchführen', color: "red"});
					})
				}
			}).render('#paypal-checkout-container'); // Renders the PayPal button
		};	

		document.head.appendChild(script);

    }

    renderSumUpCheckout(comp, provider, currency, callback){
       console.log('renderSumUpCheckout')
    }

    renderSumUpSubscription(comp, provider, payload, callback){
        console.log('renderSumUpSubscription')
    }

}

export default new Payment();
