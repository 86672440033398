import APIService from '../services/api.service';
import transcoderService from '../services/webSocket.service';

const state = {
    subscription: {},
    payment: {result:[]},
    auth_user: {},
    cart: { result: [] },
    config: {},
    categorie: {},
    categories: [],
    counterVideoUpload: {
      result: 0
    },
    draft: {},
    drafts: [],
    encodeWebSocket: {
        result: []
    },
    email: {},
    emails: [],
    explicitConsent: {},
    gallery: {},
    galleries: [],
    galleryImages: {},
    image: {},
    images: [],
    licence: {},
    lastSeenStat: [],
    lastSeenVideos: { result: [] },
    optionalDraft: [],
    plan: {},
    plans: [],
    playlist: {},
    playlists: [],
    playlistVideos: {
      result: []
    },
    providers: [],
    product: {},
    products: [],
    services: [],
    search: [],
    slides: {
      result: []
    },
    shop: [],
    subscriptions: {
      result: []
    },
    stats: {
      result:{
        videos:{},
        users:{},
        disk:{},
        streams:{}
      },
    },
    stream: {},
    streams: [],
    trigger: {},
    triggers: [],
    user: {},
    users: [],
    video: {},
    videos: [],
    webshop_user: {},
    
};

// const getters = {
//     get: (state) => (key) => {
//       if ( typeof state[key].result !== "undefined"  ){
//         return state[key].result
//       }
//       return []
//     }
// };

const getters = {
    get: (state) => (key) => {
        if (state.hasOwnProperty(key) && typeof state[key].result !== "undefined") {
            return state[key].result;
        }
        return [];
    },
};

const mutations = {
    set: (state, payload) => {
        state[payload.state] = payload.data
    },
    new: (state, payload) => {
        if ( typeof state[payload.state] !== "undefined" && Array.isArray(state[payload.state])  ) state[payload.state].result.push(payload.data)
    },
    countVideoUpload: (state, payload) => {
        state.counterVideoUpload.result = payload
    },
    setData: (state, payload) => {
        state.encodeWebSocket.result.push(payload.data);
    },
    setPayment: (state, payload) => {
        if (!state.payment.result.some(item => item.id === payload.id)) {
            state.payment.result.push(payload);
        }
    },
    removePayment: (state, payload) => {
        state.payment.result.splice(payload, 1);
    },
    deleteData: (state, payload) => {
        state.encodeWebSocket.result.splice(payload, 1);
    },
    setExplicit: (state, explicitConsent) => state.explicitConsent = explicitConsent
};

const actions = {

    setPayment({commit}, val){
        commit('setPayment', val);
    },

    removePayment({commit}, val){
        commit('removePayment', val);
    },

    deleteData({commit}, payload){
        commit('deleteData', payload);
    },

    countVideos({commit}, val){
        commit('countVideoUpload', val);
    },

    fetch({ commit, getters }, { endpoint, state, host, header }) {

        return APIService.get(endpoint, host, header)
        .then((response) => {
            commit('set', { state: state, data: response.data });
            return Promise.resolve(response.data);
        }).catch((error) => {
            const { config, response } = error;

            if (response) {
                const { status } = response;

                if (status === 401) {
                    if (typeof getters.get('config') === "undefined") {
                        APIService.logout();
                    }
                    APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                        return APIService.resolveOrigin(config).then((response) => {
                            commit('set', { state: state, data: response.data });
                            return Promise.resolve(response.data);
                        })
                    }).catch((error) => {
                        APIService.setResponse({ message: error.response.data.message, color: "red" });
                        APIService.logout();
                    })
                } else if (status === 404) {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                } else if (status === 403) {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                } else if (status === 500) {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                } else if (status === 502) {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                } else if (status === 503) {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                } else {
                    APIService.setResponse({ message: response.data.message, color: "red" });
                    return Promise.reject(error)
                }
            }
        })
    },



    post({ commit, getters }, {endpoint, state, event, host, header, message = true}) {

        let object = event;
        if( event instanceof Event ){
            object = APIService.getFormData( APIService.getForm(event) );
        }

        return APIService.post(endpoint, object, host, header)
        .then((response) => {
            commit('new', {state : state, data : response.data.result});
            if(message){
                APIService.setResponse({message: 'Der Eintrag wurde erfolgreich erstellt', color: "green"});
            }
            return Promise.resolve(response.data);
        }).catch((error) => {
            
            const { config, response: { status } } = error;

            const originalRequest = config;
            if( status === 401){
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${ getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response) => {
                        commit('new', {state : state, data : response.data.result});
                        if(message){
                            APIService.setResponse({message: 'Der Eintrag wurde erfolgreich erstellt', color: "green"});
                        }
                        return Promise.resolve(response.data);
                    })
                }).catch((error) => {
                    APIService.setResponse({message: error.response.data.message, color: "red"});
                    APIService.logout();
                })
            }
            else{
              if(message){
                APIService.setResponse({message: error.response.data.message, color: "red"});
              }
              return Promise.reject(error)
            }
        })
    },

    uploadVideo({ getters, commit }, {endpoint, host, files, metadata}) {

        APIService.uploadToken(btoa(`${ getters.get('config').licence}:`))
        .then(() =>{

            for (var i = 0; i < files.video.length; i++) {

                const video = files.video[i].file
                let formData = new FormData();

                formData.append(`video`, video);
                formData.append('metadata', JSON.stringify(metadata));

                let uploadKey = 'upload-' + (new Date()).getTime();
                APIService.setResponse({message: 0, respKey: uploadKey}, 'interactivities');
                
                // var content;
                // var fileName;
                // var reader = new FileReader();

                var config = {
                    onUploadProgress(progressEvent) {
                        var uploadPercentage = 0;
                        uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                        APIService.setProgressResponse({message: uploadPercentage, respKey: uploadKey});
                        // if( uploadPercentage === 100 ){
                        //     reader.onload = (function(file) {
                        //         contuploadent = decodeURI(file.name)
                        //         fileName = content
                        //         // Größe des Files
                        //         // var fileSize = Math.round(file.size/1024)
                        //     })(video);
                        //     // APIService.setProgressResponse({message: `Das Video ${content.length > 24 ? content.slice(0, 24) + '...' : content} wird konvertiert`, respKey: uploadKey});
                        // }
                    }
                }
                
                APIService.upload(endpoint, host, formData, config.onUploadProgress)
                .then((response) => {
                    APIService.setResponse({message: 'Upload erfolgreich. Die Konvertierung des Videos beginnt gleich.', color: 'blue'});
                    APIService.closeProgressResponse(uploadKey);
                    transcoderService.onSubmit(response.data.result)
                    commit('setData', {state: 'encodeWebSocket' , data: response.data.result.title});
                    return Promise.resolve(response.data);
                }).catch((error) => {
                    APIService.closeProgressResponse(uploadKey);
                    APIService.setResponse({message: 'Es ist ein Fehler aufgetreten.', color: "red"});
                    return Promise.reject(error);
                })

            } // Ende for
        })
        // Ende then()
    },

    uploadFiles({ getters }, {endpoint, files, host, metadata, message = true}) {

        let uploadKey = 'upload-' + (new Date()).getTime();
        APIService.setResponse({message: 0, respKey: uploadKey}, 'interactivities');
        var uploadPercentage = 0;
        var config = {
            onUploadProgress(progressEvent) {
              APIService.setProgressResponse({message: uploadPercentage, respKey: uploadKey});
              uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          }
        };
        var formData = APIService.getMultipartFormData(files)
        formData.append('metadata', JSON.stringify(metadata))

        return APIService.uploadToken(btoa(`${ getters.get('config').licence}:`))
        .then(() => {
            return APIService.upload(endpoint, host, formData, config.onUploadProgress)
            .then((response) => {
                console.log("response im mainStore", response);
                // APIService.setResponse({startUpload : false, uploadRunning : false});
                APIService.closeProgressResponse(uploadKey);
                if(message){
                    APIService.setResponse({message: 'Die Datei wurde erfolgreich hochgeladen', color: "green"});
                }
                return Promise.resolve(response.data);
            }).catch((error) => {
                APIService.closeProgressResponse(uploadKey);
                if(message){
                    APIService.setResponse({message: `${error}`, color: "red"});
                }
                
                return Promise.reject(error);
            })
        }).catch((error) => {
            if(message){
                APIService.setResponse({message: `Test${error}`, color: "red"});
            }
            return Promise.reject(error);
        })
    },

    put({ getters }, {endpoint, event, host, header, message = true}) {

        let object = event;
        if( event instanceof Event ){
            object = APIService.getFormData( APIService.getForm(event) );
        }

        return APIService.put(endpoint, object, host, header)
        .then((response) => {
            if(message){
                APIService.setResponse({message: 'Der Eintrag ist gespeichert.', color: "green"});
            }
            return Promise.resolve(response.data);
        }).catch((error) => {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if( status === 401){
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${ getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response) => {
                        if(message){
                            APIService.setResponse({message: 'Der Eintrag ist gespeichert.', color: "green"});
                        }
                        return Promise.resolve(response.data);
                    })
                }).catch((error) => {
                  APIService.setResponse({message: error.response.data.message, color: "red"});
                  APIService.logout();
                })
            }
            else{
              if (message) {
                APIService.setResponse({message: error.response.data.message, color: "red"});
              }
              return Promise.reject(error)
            }
        })
    },

    patch({ getters }, {endpoint, event, host, header, message = true}) {

        let object = event;
        if( event instanceof Event ){
            object = APIService.getFormData( APIService.getForm(event) );
        }

        return APIService.patch(endpoint, object, host, header)
        .then((response) => {
            if(message){
                APIService.setResponse({message: 'Der Eintrag ist gespeichert.', color: "green"});
            }
            return Promise.resolve(response.data);
        }).catch((error) => {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if( status === 401){
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${ getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response) => {
                        if(message){
                            APIService.setResponse({message: 'DDer Eintrag ist gespeichert.', color: "green"});
                        }
                        return Promise.resolve(response.data);
                    })
                }).catch((error) => {
                    APIService.setResponse({message: error.response.data.message, color: "red"});
                    APIService.logout();
                })
            }
            else {
                if(message){
                    APIService.setResponse({message: error.response.data.message, color: "red"});
                }
                return Promise.reject(error)
            }
        })
    },

    delete({ getters }, {endpoint, host, message = true}) {
        return APIService.delete(endpoint, host)
        .then((response) => {
            if(message){
                APIService.setResponse({message: 'Der Eintrag ist gelöscht.', color: "green"});
            }
            return Promise.resolve(response.data);
        }).catch((error) => {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if( status === 401){
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${ getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then(() => {
                        if(message){
                            APIService.setResponse({message: 'Der Eintrag wurde gelöscht.', color: "green"});
                        }
                        return Promise.resolve(response.data);
                    })
                }).catch((error) => {
                    APIService.setResponse({message: error.response.data.message, color: "red"});
                    APIService.logout();
                })
            }
            else{
                APIService.setResponse({message: error.response.data.message, color: "red"});
                return Promise.reject(error)
            }
        })
    }

};

export default {
  state,
  getters,
  actions,
  mutations
};
