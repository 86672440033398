<template>
  <div>
    <!-- ProfilBild & Name -->
    <v-row class="align-center justify-start mt-4">
      <v-col cols="1">
        <div class="mt-2">
          <div class="d-flex justify-center">
            <v-img max-width="100px" height="100px" style="border-radius: 80px;" :src="imageSource(oneUser, 100, 100)">
              <template v-slot:placeholder>
                <v-icon>mdi-camera</v-icon>
              </template>
            </v-img>
          </div>
          <div class="d-flex justify-center">
            <v-file-input
              style="flex: 0 0 auto"
              prepend-icon="mdi-camera"
              hide-input
              v-model="profileImg"
              @change="profilBildUpload()"
              show-size
            ></v-file-input>
          </div>
        </div>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="11" class="align-items-center">
        <div class="ml-8">
          <v-dialog
            v-model="userNameDialog"
            transition="dialog-top-transition"
            max-width="360"
          >
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                    <span class="display-1 font-weight-bold">{{ oneUser.name }}</span>
                    <v-icon 
                      color="primary" 
                      class="showIcon mt-n1 ml-2"
                      size="18"
                    >
                      mdi-pencil
                    </v-icon>
                  </div>
                </template>
                <span>Name ändern</span>
              </v-tooltip>
            </template>

            <v-card outlined>
              <v-card-title class="text-h5 pb-2">
                Name ändern
              </v-card-title>
              <v-card-text class="pb-0">Nenne uns bitte Vor- und Nachname?</v-card-text>
              <v-form @submit.prevent="editUserProfile({ name: oneUser.name }), userNameDialog = false">
                <v-card-text class="px-6">
                  <v-text-field
                    label="Dein Vor- und Nachname"
                    v-model="oneUser.name"
                    autofocus
                    single-line
                    outlined
                    dense
                  ></v-text-field>
                  <div class="d-flex justify-end">                          
                    <v-btn
                      color="green darken-1"
                      outlined
                      small
                      type="submit"
                    >
                      Speichern
                    </v-btn>
                  </div>  
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          
          <v-chip
            v-if="currentUser(['ROLE_END_USER'])"
            class="mt-2"
            color="blue"
            round
            outlined
            small
          >
            User
          </v-chip>
          <v-chip
            v-else
            class="mt-2"
            color="blue"
            round
            outlined
            small
          >
            Admin
          </v-chip>
        </div>
      </v-col>
      
      <v-col cols="6" v-if="currentUser(['ROLE_TENANT'])">
        <v-card class="pa-4">
          <div class="d-flex justify-space-between">
            <p class="headline ">Aktueller Plan</p>
          </div>
          <v-row class="pa-0 ma-0 align-item-start justify-space-between">
            <v-col class="pa-0 ma-0">
              <v-chip
                color="purple"
                class="text--lighten-2 lighten-2"
                outlined
              >
                {{get('product').label}}
              </v-chip>
            </v-col>
            <v-col class="pa-0 ma-0" cols="8">
              <div class="d-flex justify-end">

                <h2 v-if="isCreatorSubActive()" class="font-weight-black mt-n1 purple--text right--text">{{formatCurrency(get('product').price)}}<span class="caption font-weight-bold">/mtl.</span></h2>                
                <v-progress-linear
                  :value="daysLeft(get('licence').created, 7)"
                  color="primary"
                  height="21"
                  class="mt-1"
                  v-else
                >
                  <template v-slot:default="{ value }">
                    <p class="caption pt-4">{{ Math.round((7 / 100) * value) }} von 7 Tagen</p>
                  </template>
                </v-progress-linear>
              </div>
            </v-col>
          </v-row>
          <!-- <div class="d-flex mt-4 mb-0 pa-0 justify-space-between">
            <p v-if="get('webshop_user').subscriptions != undefined" class="mt-5 mb-0 pa-0 grey--text caption"><strong>Endet am:</strong> {{sevenDaysOnTop(get('licence').created)}}</p>
            <p v-if="isCreatorSubActive()" class=" pa-0 mb-0 grey--text caption"><strong class="mr-1">Endet zum:</strong>{{formDate(get('webshop_user')?.subscriptions[0].billing_info.next_billing_time, 'DD.MM.YYYY') }}</p>
            <p v-else class="mt-2 mb-0 pa-0 grey--text caption text--center"><strong>Test Phase endet zum:</strong> {{sevenDaysOnTop(get('licence').created) }}</p>
            <div v-if="isCreatorSubActive()" class="pa-0 mt-n1">
              <span v-if="isCreatorSubActive()" class="caption click_cursor secondary--text pa-0 mb-0" @click="suspendPlan(get('webshop_user')?.subscriptions[0].id)">Kündigen</span>
              <span v-else class="caption click_cursor green--text pa-0 mb-0" @click="reactivatePlan(get('webshop_user')?.subscriptions[0].id)">Reaktivieren</span>
            </div>
            <v-progress-circular v-if="suspendLoader" :width="2" :size="16" indeterminate small></v-progress-circular>
          </div> -->

        </v-card>
      </v-col>
      <!-- <v-col v-if="currentUser(['ROLE_TENANT'])" cols="6">

        <v-card v-if="isCreatorSubActive()" class="pa-4 ">
          <div class="d-flex ">
            <p class="headline">Zahlungsart</p>
            <v-icon size="16px" class="mt-n3 ml-3 md-18" color="#00C3FF">mdi-pencil</v-icon>
          </div>
          <v-row class="">
            <v-col cols="12">
              <img src="@/assets/img/Paypal_Logo.png" width="75px">
              <p class="subtitle-2 mt-2 mb-0">{{get('webshop_user')?.subscriptions[0]?.subscriber.email_address}}</p>
            </v-col>
          </v-row>
        </v-card>
        
        <v-card v-else class="pa-4 animated-gradient-dBlue-red-radial">
          <div class="d-flex">
            <p class="headline">Testphase beenden?</p>
          </div>
          <p class="body-2">Nutzte bereits jetzt den vollen Umfang deiner Mediathek</p>
          <v-btn 
            v-if="isTester()"
            rounded
            @click="linkTo('checkout')"
          >
            Upgrade Now
          </v-btn>
        </v-card>
      </v-col> -->
    </v-row>
    
  
    <v-row>
      <v-col cols="12">
        <div class="title">Persönliche Daten</div>
        <v-card class="pa-6 mt-2">
          <v-row>
            <v-col>
              
              <p class="secondary--text font-weight-bold pa-0 ma-0">E-Mail</p>
              <v-dialog
                v-model="userMailDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="font-weight-medium">{{ get('auth_user').email }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>E-Mail ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    E-Mail ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wie lautet deine E-Mailadresse?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserMail(get('auth_user').id, get('auth_user').email), userMailDialog = false"
                    v-model="valid.email"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Dein Vor- und Nachname"
                        v-model="get('auth_user').email"
                        autofocus
                        single-line
                        outlined
                        dense
                        :rules="eMailRules"
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.email"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Passwort</p>
              
              <v-dialog
                v-model="userPWDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2">●●●●●●●●●●●●●●●●●</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Passwort ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Passwort ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Vergib ein sicheres Passwort aus mindestens 6 Zeichen.</v-card-text>
                  <v-form 
                    @submit.prevent="editUserPwd(), userPWDialog = false"
                    v-model="valid.password"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Neues Kennwort"
                        v-model="password"
                        type="password"
                        :rules="passwordRules"
                        :append-icon="showPwIcon ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        @click:append="showPwIcon =!showPwIcon"
                        :type="showPwIcon ? 'text' : 'password'"
                        class="mt-2"
                        single-line
                        outlined
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.password"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col>
              
              <p class="secondary--text font-weight-bold pa-0 ma-0">Vor- und Nachname</p>
             
              <v-dialog
                v-model="userNameDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ oneUser.name }} </span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Name ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Name ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Nenne uns bitte Vor- und Nachname?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserProfile({ name: oneUser.name }), userNameDialog = false"
                    v-model="valid.name"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Dein Vor- und Nachname"
                        v-model="oneUser.name"
                        autofocus
                        single-line
                        outlined
                        dense
                        :rules="userNameRules"
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.name"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Geburtsdatum</p>
              <v-dialog
                v-model="userBirthDayDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ formDate(oneUser.birthday) }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Geburtsdatum ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Geburtsdatum ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wann hast du Geburtstag?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserProfile({birthday: birthday + ' 00:00:00'}), userBirthDayDialog = false"
                    v-model="valid.birtdate"
                  
                  >
                    <v-card-text class="px-6">
                      <v-menu
                        ref="birthdateMenu"
                        v-model="birthdateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthday"
                            label="Wann hast du Geburtstag?"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="birtdayRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="birthday"
                          :active-picker.sync="activePicker"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                          min="1950-01-01"
                          @change="editUserProfile({birthday: birthday + ' 00:00:00'}), birthdateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.birthdate"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
   
            <v-col v-if="currentUser(['ROLE_TENANT'])">
              <p class="secondary--text font-weight-bold pa-0 ma-0">Adresse</p>
              <v-dialog
                v-model="userAddressDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').address }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Adresse ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Adresse ändern
                  </v-card-title>
                  <v-card-text class="pb-0">In welche Straße wohnst du?</v-card-text>
                  <v-form 
                    @submit.prevent="editWebShopUser({address: get('webshop_user').address}), userAddressDialog = false"
                    v-model="valid.address"  
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Straße und Hausnummer"
                        placeholder="Straße und Hausnummer"
                        v-model="get('webshop_user').address"
                        single-line
                        required
                        outlined
                        autofocus
                        :rules="addressRules"
                      />
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Stadt</p>
              <v-dialog
                v-model="userTownDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').town }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Land ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Land ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Woher kommst du?</v-card-text>
                  <v-form @submit.prevent="editUserProfile({country: get('webshop_user').town}), userTownDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Dein Herkuntsland"
                        placeholder="Dein Herkuntsland"
                        v-model="get('webshop_user').town"
                        single-line
                        required
                        outlined
                        autofocus
                      />
                    </v-card-text>
                    <div class="d-flex justify-end">                          
                      <v-btn
                        color="green darken-1"
                        outlined
                        small
                        type="submit"
                      >
                        Speichern
                      </v-btn>
                    </div>  
                  </v-form>
                </v-card>
              </v-dialog>
              
            </v-col>

            <v-col v-if="currentUser(['ROLE_TENANT'])"  >
              <p class="secondary--text font-weight-bold pa-0 ma-0">PLZ</p>
              <v-dialog
                v-model="userPLZDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').zip }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Postleitzahl ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Postleitzahl ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wie lautet deine Postleitzahl?</v-card-text>
                  <v-form @submit.prevent="editWebShopUser({zip: get('webshop_user').zip}), userPLZDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Postleitzahl"
                        placeholder="Postleitzahl"
                        v-model="get('webshop_user').zip"
                        single-line
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        required
                        outlined
                        autofocus
                        :rules="zipRules"
                      />
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Herkunftsland</p>

              <v-dialog
                v-model="userCountryDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').country }}</span>
                        <v-icon 
                          color="primary" 
                          class="showIcon mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Land ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Land ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Woher kommst du?</v-card-text>
                  <v-form @submit.prevent="editUserProfile({country: get('webshop_user').country}), userCountryDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Dein Herkuntsland"
                        placeholder="Dein Herkuntsland"
                        v-model="oneUser.country"
                        single-line
                        required
                        outlined
                        autofocus
                      />
                    </v-card-text>
                    <div class="d-flex justify-end">                          
                      <v-btn
                        color="green darken-1"
                        outlined
                        small
                        type="submit"
                      >
                        Speichern
                      </v-btn>
                    </div>  
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="6" v-if="currentUser(['ROLE_TENANT'])">
        <v-card class="pa-4">
          <div class="d-flex justify-space-between">
            <p class="headline">Aktueller Plan</p>
            <!-- <v-btn fab @click="linkTo('checkout')">
              <v-icon size="16px" class="mt-n3 ml-3 md-18" color="#00C3FF">mdi-pencil</v-icon>
            </v-btn> -->
          </div>
          <v-row class="pa-0 ma-0 align-item-start justify-space-between">
            <v-col class="pa-0 ma-0">
              <v-chip
                color="purple"
                class="text--lighten-2 lighten-2"
                outlined
              >
                {{get('product').label}}
              </v-chip>
            </v-col>
            <v-col class="pa-0 ma-0" cols="8">
              <div class="d-flex justify-end">

                <!-- MIT Subscription -->
                <h2 v-if="isCreatorSubActive()" class="font-weight-black mt-n1 purple--text right--text">{{formatCurrency(get('product').price)}}<span class="caption font-weight-bold">/mtl.</span></h2>

                <!-- OHNE Subscription -->
                <v-progress-linear
                  :value="daysLeft(get('licence').created, 7)"
                  color="primary"
                  height="21"
                  class="mt-1"
                  v-else
                >
                  <template v-slot:default="{ value }">
                    <p class="caption pt-4">{{ Math.round((7 / 100) * value) }} von 7 Tagen</p>
                  </template>
                </v-progress-linear>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex mt-4 mb-0 pa-0 justify-space-between">
            <!-- <p v-if="get('webshop_user').subscriptions.length > 0" class="mt-5 mb-0 pa-0 grey--text caption"><strong>Endet am:</strong> {{sevenDaysOnTop(get('licence').created)}}</p> -->
            <!-- <p v-if="isCreatorSubActive()" class=" pa-0 mb-0 grey--text caption"><strong class="mr-1">Endet zum:</strong>{{formDate(get('webshop_user')?.subscriptions[0].billing_info.next_billing_time, 'DD.MM.YYYY') }}</p> -->
            <!-- <p v-else class="mt-2 mb-0 pa-0 grey--text caption text--center"><strong>Test Phase endet zum:</strong> {{sevenDaysOnTop(get('licence').created) }}</p> -->
            <!-- v-if="isTester()" -->
            <v-btn tile
              rounded
              @click="linkTo('checkout')"
            >
              Upgrade Now
            </v-btn>
            <div v-if="isCreatorSubActive() && get('webshop_user').subscriptions.length > 0" class="pa-0 mt-n1">
              <span v-if="isCreatorSubActive()" class="caption click_cursor secondary--text pa-0 mb-0" @click="suspendPlan(get('webshop_user').subscriptions[0].id)">Kündigen</span>
              <span v-else class="caption click_cursor green--text pa-0 mb-0" @click="reactivatePlan(get('webshop_user').subscriptions[0].id)">Reaktivieren</span>
            </div>
            <v-progress-circular v-if="suspendLoader" :width="2" :size="16" indeterminate small></v-progress-circular>
          </div>

        </v-card>
      </v-col>
    </v-row>

    <v-row >
      <v-col v-if="currentUser(['ROLE_TENANT'])" cols="6">

        <!-- V-Card MIT Subscription -->
        <v-card v-if="isCreatorSubActive() && get('webshop_user').subscriptions.length > 0" class="pa-4">
          <div class="d-flex ">
            <p class="headline">Zahlungsart</p>
            <v-icon size="16px" class="mt-n3 ml-3 md-18" color="#00C3FF">mdi-pencil</v-icon>
          </div>
          <v-row class="">
            <v-col cols="12">
              <img src="@/assets/img/Paypal_Logo.png" width="75px">
              <p class="subtitle-2 mt-2 mb-0">{{get('webshop_user').subscriptions[0].subscriber.email_address}}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row >
      <v-col v-if="currentUser(['ROLE_USER'])" cols="6">

        <!-- V-Card MIT Subscription
        <v-card v-if="isConsumerSubActive()" class="pa-4">
          <div class="d-flex ">
            <p class="headline">Zahlungsart</p>
            <v-icon size="16px" class="mt-n3 ml-3 md-18" color="#00C3FF">mdi-pencil</v-icon>
          </div>
          <v-row class="">
            <v-col cols="12">
              <img src="@/assets/img/Paypal_Logo.png" width="75px">
              <p class="subtitle-2 mt-2 mb-0">{{get('webshop_user')?.subscriptions[0].subscriber.email_address}}</p>
            </v-col>
          </v-row>
        </v-card>
      -->
      </v-col>
    </v-row>

    <!-- <keep-alive>
      <component :is="isSubscription"/>
    </keep-alive> -->

  </div>
</template>
<script>
import kontoLoeschen from '@/components/modal/kontoLoeschen'
import userRole from '@/services/userRoleService'
import { mapGetters } from 'vuex'
import APIService from '@/services/api.service';
import AppService from '@/services/app.service';
import moment from 'moment';
// const subscription = () => import('@/components/modal/adminSubscription');


export default {
  data(){
    return {
      email: '',
      password: '',
      cancelBtn: true,
      activePicker: null,
      birthdate: '',
      birthday: '', 
      suspendLoader: false,
      birthdateMenu: false,
      userTownDialog: false,
      userAddressDialog: false,
      userBirthDayDialog: false,
      userCountryDialog: false,
      userPLZDialog: false,
      userMailDialog: false,
      userNameDialog: false,
      userPWDialog: false,  
      showPwIcon: false,
      subs: '',
      valid: false,
      profileImg:              [],
      fileRecordsForUpload:    [],
      certificates:            [],
      profil_tabs: null,
      descriptionVorschau: false,
      content:  "<h1>Initialer Kontent<h1>" ,
      userNameDialog: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      eMailRules: [
        v => !!v || 'Die E-Mailadresse ist erforderlich.',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Falsches Format für eine E-Mailadresse'
      ],
      passwordRules: [
        v => !!v || 'Bitte Passwort eingeben',
        v => (v && v.length >= 6) || 'Das Passwort ist zu kurz'
      ],
      userNameRules: [
        v => !!v || 'Bitte Namen eingeben',
        v => (v && v.length <= 30) || 'Der Name darf nicht mehr als 30 Zeichen haben.',
        v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
      ],
      addressRules: [
        v => !!v || 'Bitte Adresse eingeben',
        v => /^[a-zA-ZäöüÄÖÜß\s]+\s?\d+[a-zA-Z]?$/.test(v) || 'Falsches Format für eine deutsche Adresse'
      ],

      birthdateRules: [
        v => !!v || 'Bitte Geburtsdatum eingeben',
        v => /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(v) || 'Falsches Format für ein Geburtsdatum'
      ],

      zipRules: [
        v => !!v || 'Preis darf nicht leer sein',
        v => !isNaN(parseFloat(v)) && !isNaN(v) || 'Preis darf nur Zahlen und Komma enthalten',
        v => parseFloat(v) >= 0 || 'Preis muss 0 oder positiv sein'
      ],
    }
  },

  computed: {
    ...mapGetters(["get"]),
    oneUser:{
      get(){
        if ( typeof this.$store.state.main.user.result !== "undefined"  ) return this.$store.state.main.user.result
      },
      set(val){
        this.$emit("input", val)
      }
    },
    authUser:{
      get(){
        if ( typeof this.$store.state.main.auth_user.result !== "undefined"  ) return this.$store.state.main.auth_user.result
      },
      set(val){
        this.$emit("input", val)
      }
    },
    // isSubscription:{
    //     get(){
    //       if ( typeof this.$store.state.main.products.result !== "undefined"  ) return subscription;
    //     },
    //     set(val){
    //       this.$emit("input", val)
    //     }
    // }
  },

  methods: {

    isTester(){
      return AppService.isTester()
    },
    isCreatorSubActive(){
      return AppService.isCreatorSubActive()
    },

    isConsumerSubActive(){
      return AppService.isConsumerSubActive()
    },

    upgradePlan(name) {
      this.$store.dispatch('fetch', { endpoint: `/api/v1/products`, host: 'getWebshopHost', state: 'products'})
      this.$store.dispatch('fetch', { endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`, host: 'getWebshopHost', state: 'product'})
      this.$store.dispatch('modal/open', name)
    },

    suspendPlan(planId){
      this.suspendLoader = true
      this.$store.dispatch('patch', {
        endpoint: `/api/v1/subscriptions/${planId}/suspend`,
        event: {licence_id: this.$store.state.main.licence.result.id},
        host: 'getWebshopHost',
        message: false
      })
      .then(() => {
        this.$store.dispatch('fetch', {
          endpoint: `/api/v1/licences/${this.$store.state.main.config.result.licence}`,
          host: 'getAuthHost',
          state: 'licence'
        }).then(() => {
          this.suspendLoader = false
          APIService.setResponse({message: "Der Plan wurde gekündigt.", color: "green"});
        })
      })
    },

    reactivatePlan(planId){
      this.suspendLoader = true
      this.$store.dispatch('patch', {
        endpoint: `/api/v1/subscriptions/${planId}/activate`,
        event: { licence_id: this.$store.state.main.licence.result.id },
        host: 'getWebshopHost',
        message: false
      })
      .then(() => {
        this.$store.dispatch('fetch', {
          endpoint: `/api/v1/licences/${this.$store.state.main.config.result.licence}`,
          host: 'getAuthHost',
          state: 'licence'
        }).then(() => {
          this.suspendLoader = false
          APIService.setResponse({ message: "Schön, dass du bleibst!", color: "green" });
        })
      })
    },

    sevenDaysOnTop(date){
      // Startdatum als String
      const startDate = date
      // Startdatum in Date-Objekt konvertieren
      const startDateObj = new Date(startDate);
      // 31 Tage hinzufügen
      startDateObj.setDate(startDateObj.getDate() + 7);
      // Enddatum in String konvertieren
      const endDate = startDateObj.toISOString().split("T")[0];
      // console.log(endDate);
      return this.formDate(endDate, 'DD.MM.YYYY');
    },

    daysLeft(startDate, timeRange) {
      // Startdatum in Date-Objekt konvertieren
      const startDateObj = new Date(startDate);

      // Heutiges Datum
      const today = new Date();
      // Anzahl der verbleibenden Tage berechnen
      const daysLeft = Math.ceil((today - startDateObj) / (1000 * 60 * 60 * 24));

      // Prüfen, ob der Zeitraum innerhalb des angegebenen Zeitraums liegt
      if (daysLeft < timeRange) {
        var dayCount = (100/timeRange) * daysLeft
        return dayCount;
      } else {
        return 100
      }

    },
    
    editUserProfile(object){

      if (object.name.length > 0) {
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${object.id}`,
          event: object,
          message: false
        }).then(() => {
          APIService.dispatch('fetch', {
            endpoint: `/api/v1/users/${object.id}`,
          })
          APIService.setResponse({message: "Name gespeichert.", color: "green"});
        })
      }
      else{
        APIService.setResponse({ message: "Keine Änderung vorgenommen.", color: "blue"})
      }
    },

    editUserMail(id, email){
      if (this.authUser.email.length >= 0) {
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.authUser.id}`,
          event: { name: this.authUser.email},
          host: 'getAuthHost',
          message: false
        }).then(() => {
          APIService.setResponse({message: "Deine neue E-Mail ist gespeichert.", color: "green"});
        })
      }
      else{
        APIService.setResponse({ message: "Keine Änderung vorgenommen.", color: "blue"})
      }
    },

    editUserPwd(){
      let payload = { email: this.authUser.email}

      // --------------------->
      // DAS MUSS GEÄNDERT WERDEN!!!
      // ==> in was? (keki | dez.23)
      // --------------------->
      if(this.password.length > 0){
        payload = { password: this.password, email: this.authUser.email}
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.authUser.id}`,
          event: payload,
          host: 'getAuthHost',
          message: false
        }).then(() => {
          APIService.setResponse({ message: "Das Passwort wurde geändert.", color: "green"})
        })
      }
      else{
        APIService.setResponse({ message: "Keine Änderung vorgenommen.", color: "blue"})
      }
      // <---------------------


    },

    linkTo(route) {
      this.$router.push('/' + route)
    },

    // linkTo(adress){
    //   window.open(adress, "_blank")
    //   // window.location.href = adress
    // },

    openWarning(name){
			this.$store.dispatch("modal/open", name)
		},

    saveSocialMedia(e){
      this.$store.dispatch('patch',
      {
        endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
        event: e
      })

    },



    editUserMail(id, email){
      if (this.authUser.email.length > 0) {
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.authUser.id}`,
          event: { name: this.authUser.email},
          host: 'getAuthHost',
          message: false
        }).then(() => {
          APIService.setResponse({message: "Deine neue E-Mail ist gespeichert.", color: "green"});
        })
      }
    },

    currentUser(roles) {
      return userRole.hasRole(roles);
    },

    formatCurrency(number){
      return new Intl.NumberFormat('de-DE',
      {
        style: 'currency',
        currency: 'EUR'
      })
      .format(number)
    },

    formDate(value, format){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    imageSource(oneUser){
      let src = './assets/logo/LogoMark_blue_new.png';
      if( oneUser.image != null){
        src = APIService.getApiHost() + `/${oneUser.image}` ;
      }
      return src;
    },

    onBeforeDeleteImage: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        if (confirm('Das Bild wirklich löschen?')) {
          this.$refs.logo.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },

    save(){
      let payload = { email: this.authUser.email}
      if(this.password.length > 0){
        payload = { password: this.password, email: this.authUser.email}
      }

      this.$store.dispatch('patch',
      {
        endpoint: `/api/v1/users/${this.authUser.id}`,
        event: payload,
        host: 'getAuthHost'
      })

      // Namensänderung
      if (this.oneUser.name.length > 0) {
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
          event: { name: this.oneUser.name},
          message: false
        })
        .then((response) =>{
          APIService.setResponse({ message: `Änderung erfolgreich`, color: "green" });
          APIService.setUser(response.result);
        });

      }
      this.$store.dispatch("modal/close", 'profil');
    },

    formDate(value, format){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    close(name) {
      this.$store.dispatch("modal/close", name)
    },

    profilBildUpload() {
      // setTimeout(() => {

        this.$store.dispatch('uploadFiles',
        {
          endpoint: `/api/v1/users/upload`,
          files: {image: this.profileImg},
          metadata: { width: 100, height: 100 },
          message: false
        }).then(() => {
          APIService.setResponse({ message: `Dein neues Profilbild sieht toll aus.`, color: "blue" });
          this.resetForm()
          this.$store.dispatch('fetch', {
            endpoint: `/api/v1/users/me`,
            state: 'user',
          })
        }).catch((error) => {
          console.log("error auf userDetails",error)
          APIService.setResponse({ message: `Upload fehlgeschlagen`, color: "red" });
        });
      // }, 50);
    },

    resetForm(){
      this.profilBild = []
      // this.logo = []
      this.fileRecordsForUpload = []
    },

    imgUpload() {
      setTimeout(() => {

        this.$store.dispatch('uploadFiles',
        {
          endpoint: `/api/v1/files`,
          files: {file: this.certificates},
          metadata: { width: 100, height: 100 },
          message: false
        }).then(() => {
          APIService.setResponse({ message: `Upload erfolgreich.`, color: "green" });
          this.$store.dispatch('fetch', { endpoint: `/api/v1/users/me`, state: 'user'}).then(() => {
          })
        });
      }, 50);
    },
  },
  created(){

    this.$store.dispatch('fetch', {
      endpoint: `/api/v1/users/me`,
      state: 'webshop_user',
      host: 'getWebshopHost'
    })
    this.$store.dispatch('fetch', {
      endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
      state: 'product',
      host: 'getWebshopHost'
    }).then(() => {
      // console.log("created | userDetails|core .")
    })
  //   this.$store.dispatch('fetch', { endpoint: `/api/v1/users/me`, state: 'user'}).then(() => {
  //     console.log("Fetch von user/me");
  //   })
  //   this.$store.dispatch('fetch', { endpoint: '/api/v1/stats', state: 'stats'})
  //   this.$store.dispatch('fetch', { endpoint: '/api/v1/files', state: 'files'})
  }
}


</script>

<style>
  .vue-file-agent.file-input-wrapper{
    border-radius: 8px !important;
    border: 1px;
    border-style: dashed;
    border-color: #00c3ff;
  }
</style>
