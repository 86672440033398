<template>

  <div>
    
    <!-- ProfilBild & Name -->
    <v-row class="align-center justify-start mt-4">
      <v-col cols="1">
        <div class="mt-2">
          <div class="d-flex justify-center">
            <v-img max-width="100px" height="100px" style="border-radius: 80px;" :src="imgSource(oneUser)">
              <template v-slot:placeholder>
                <v-icon>mdi-camera</v-icon>
              </template>
            </v-img>
          </div>
          <div class="d-flex justify-center">
            <v-file-input
              style="flex: 0 0 auto"
              prepend-icon="mdi-camera"
              hide-input
              v-model="profileImg"
              @change="profilBildUpload()"
              show-size
            ></v-file-input>
          </div>
        </div>

      </v-col>

      <v-col cols="11" class="align-items-center">
        <div class="ml-8">
          <v-dialog
            v-model="userNameDialog"
            transition="dialog-top-transition"
            max-width="360"
          >
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                    <span class="display-1 font-weight-bold">{{ oneUser.name }}</span>
                    <v-icon 
                      color="primary" 
                      class=" mt-n1 ml-2"
                      size="18"
                    >
                      mdi-pencil
                    </v-icon>
                  </div>
                </template>
                <span>Name ändern</span>
              </v-tooltip>
            </template>

            <v-card outlined>
              <v-card-title class="text-h5 pb-2">
                Name ändern
              </v-card-title>
              <v-card-text class="pb-0">Nenne uns bitte Vor- und Nachname?</v-card-text>
              <v-form @submit.prevent="editUserProfile({ name: oneUser.name }), userNameDialog = false">
                <v-card-text class="px-6">
                  <v-text-field
                    label="Dein Vor- und Nachname"
                    v-model="oneUser.name"
                    autofocus
                    single-line
                    outlined
                    dense
                  ></v-text-field>
                  <div class="d-flex justify-end">                          
                    <v-btn
                      color="green darken-1"
                      outlined
                      small
                      type="submit"
                    >
                      Speichern
                    </v-btn>
                  </div>  
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          
          <v-chip
            v-if="currentUser(['ROLE_END_USER'])"
            class="mt-2"
            color="blue"
            round
            outlined
            small
          >
            User
          </v-chip>
          <v-chip
            v-else
            class="mt-2"
            color="blue"
            round
            outlined
            small
          >
            Admin
          </v-chip>
        </div>
      </v-col>
      
      <v-col cols="6" v-if="currentUser(['ROLE_TENANT'])">
        <v-card class="pa-4">
          <div class="d-flex justify-space-between">
            <p class="headline ">Aktueller Plan</p>
          </div>
          <v-row class="pa-0 ma-0 align-item-start justify-space-between">
            <v-col class="pa-0 ma-0">
              <v-chip
                color="purple"
                class="text--lighten-2 lighten-2"
                outlined
              >
                {{get('product').label}}
              </v-chip>
            </v-col>
            <v-col v-if="get('subscription').payment" class="pa-0 ma-0">
              <p class="subtitle-2 mt-2 mb-0">{{get('subscription').payment.status}}</p>

            </v-col>

            <v-col class="pa-0 ma-0" cols="8">
              <div class="d-flex mt-4 mb-0 pa-0 justify-end">

                <h2 v-if="isCreatorSubActive()" class="font-weight-black mt-n1 purple--text right--text">{{formatCurrency(get('product').price)}}<span class="caption font-weight-bold">/mtl.</span></h2>                
                <v-progress-linear
                  :value="daysLeft(get('licence').created, 7)"
                  color="primary"
                  height="21"
                  class="mt-1"
                  v-else
                >
                  <template v-slot:default="{ value }">
                    <p class="caption pt-4">{{ Math.round((7 / 100) * value) }} von 7 Tagen</p>
                  </template>
                </v-progress-linear>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="currentUser(['ROLE_TENANT'])" cols="6">

        <subscription v-if="get('webshop_user').subscriptions.length > 0"
          :subscription.sync="get('webshop_user').subscriptions[0]"
          :index="0"
        ></subscription>
        <v-card v-else class="pa-4 animated-gradient-dBlue-red-radial">
          <div class="d-flex">
            <p class="headline">Testphase beenden?</p>
          </div>
          <p class="body-2">Nutzte bereits jetzt den vollen Umfang deiner Mediathek</p>
          <v-btn 
            v-if="isTester()"
            rounded
            @click="linkTo('checkout')"
          >
            Upgrade Now
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="title">Persönliche Daten</div>
        <v-card class="pa-6 mt-2">
          <v-row>
            <v-col>
              
              <p class="secondary--text font-weight-bold pa-0 ma-0">E-Mail</p>
              <v-dialog
                v-model="userMailDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="font-weight-medium">{{ get('auth_user').email }}</span>
                        <v-icon 
                          color="primary" 
                          class="mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>E-Mail ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    E-Mail ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wie lautet deine E-Mailadresse?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserMail(get('auth_user').id, get('auth_user').email), userMailDialog = false"
                    v-model="valid.email"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Dein Vor- und Nachname"
                        v-model="get('auth_user').email"
                        autofocus
                        single-line
                        outlined
                        dense
                        :rules="eMailRules"
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.email"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Passwort</p>
              
              <v-dialog
                v-model="userPWDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2">●●●●●●●●●●●●●●●●●</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Passwort ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Passwort ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Vergib ein sicheres Passwort aus mindestens 6 Zeichen.</v-card-text>
                  <v-form 
                    @submit.prevent="editUserPwd(), userPWDialog = false"
                    v-model="valid.password"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Neues Kennwort"
                        v-model="password"
                        type="password"
                        :rules="passwordRules"
                        :append-icon="showPwIcon ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        @click:append="showPwIcon =!showPwIcon"
                        :type="showPwIcon ? 'text' : 'password'"
                        class="mt-2"
                        single-line
                        outlined
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.password"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col>
              
              <p class="secondary--text font-weight-bold pa-0 ma-0">Vor- und Nachname</p>
             
              <v-dialog
                v-model="userNameDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ oneUser.name }} </span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Name ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Name ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Nenne uns bitte Vor- und Nachname?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserProfile({ name: oneUser.name }), userNameDialog = false"
                    v-model="valid.name"
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        label="Dein Vor- und Nachname"
                        v-model="oneUser.name"
                        autofocus
                        single-line
                        outlined
                        dense
                        :rules="userNameRules"
                      ></v-text-field>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.name"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Geburtsdatum</p>
              <v-dialog
                v-model="userBirthDayDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ formDate(oneUser.birthday) }}</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Geburtsdatum ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Geburtsdatum ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wann hast du Geburtstag?</v-card-text>
                  <v-form 
                    @submit.prevent="editUserProfile({birthday: birthday + ' 00:00:00'}), userBirthDayDialog = false"
                    v-model="valid.birtdate"
                  
                  >
                    <v-card-text class="px-6">
                      <v-menu
                        ref="birthdateMenu"
                        v-model="birthdateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthday"
                            label="Wann hast du Geburtstag?"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="birtdayRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="birthday"
                          :active-picker.sync="activePicker"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                          min="1950-01-01"
                          @change="editUserProfile({birthday: birthday + ' 00:00:00'}), birthdateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                          :disabled="!valid.birthdate"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
   
            <v-col v-if="currentUser(['ROLE_TENANT'])">
              <p class="secondary--text font-weight-bold pa-0 ma-0">Adresse</p>
              <v-dialog
                v-model="userAddressDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').address }}</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Adresse ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Adresse ändern
                  </v-card-title>
                  <v-card-text class="pb-0">In welche Straße wohnst du?</v-card-text>
                  <v-form 
                    @submit.prevent="editWebShopUser({address: get('webshop_user').address}), userAddressDialog = false"
                    v-model="valid.address"  
                  >
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Straße und Hausnummer"
                        placeholder="Straße und Hausnummer"
                        v-model="get('webshop_user').address"
                        single-line
                        required
                        outlined
                        autofocus
                        :rules="addressRules"
                      />
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Stadt</p>
              <v-dialog
                v-model="userTownDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').town }}</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Stadt ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Stadt ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Woher kommst du?</v-card-text>
                  <v-form @submit.prevent="editWebShopUser({town: get('webshop_user').town}), userTownDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Wohnort"
                        placeholder="Dortmund"
                        v-model="get('webshop_user').town"
                        single-line
                        required
                        outlined
                        autofocus
                      />
                    </v-card-text>
                    <div class="d-flex justify-end">                          
                      <v-btn
                        color="green darken-1"
                        outlined
                        small
                        type="submit"
                      >
                        Speichern
                      </v-btn>
                    </div>  
                  </v-form>
                </v-card>
              </v-dialog>
              
            </v-col>

            <v-col v-if="currentUser(['ROLE_TENANT'])"  >
              <p class="secondary--text font-weight-bold pa-0 ma-0">PLZ</p>
              <v-dialog
                v-model="userPLZDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').zip }}</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Postleitzahl ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Postleitzahl ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Wie lautet deine Postleitzahl?</v-card-text>
                  <v-form @submit.prevent="editWebShopUser({zip: get('webshop_user').zip}), userPLZDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Postleitzahl"
                        placeholder="Postleitzahl"
                        v-model="get('webshop_user').zip"
                        single-line
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        required
                        outlined
                        autofocus
                        :rules="zipRules"
                      />
                      <div class="d-flex justify-end">                          
                        <v-btn
                          color="green darken-1"
                          outlined
                          small
                          type="submit"
                        >
                          Speichern
                        </v-btn>
                      </div>  
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>

              <p class="secondary--text font-weight-bold pa-0 mt-4 mb-0">Herkunftsland</p>

              <v-dialog
                v-model="userCountryDialog"
                transition="dialog-top-transition"
                max-width="360"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="{...tooltip, ...dialog}" class="click_cursor">
                        <span class="mr-2 font-weight-medium ">{{ get('webshop_user').country }}</span>
                        <v-icon 
                          color="primary" 
                          class=" mt-n1 ml-2"
                          size="18"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <span>Land ändern</span>
                  </v-tooltip>
                </template>

                <v-card outlined>
                  <v-card-title class="text-h5 pb-2">
                    Land ändern
                  </v-card-title>
                  <v-card-text class="pb-0">Woher kommst du?</v-card-text>
                  <v-form @submit.prevent="editWebShopUser({country_id: 5}), userCountryDialog = false">
                    <v-card-text class="px-6">
                      <v-text-field
                        class="mt-2"
                        label="Dein Herkuntsland"
                        placeholder="Dein Herkuntsland"
                        v-model="get('webshop_user').country"
                        single-line
                        required
                        outlined
                        autofocus
                      />
                    </v-card-text>
                    <div class="d-flex justify-end">                          
                      <v-btn
                        color="green darken-1"
                        outlined
                        small
                        type="submit"
                      >
                        Speichern
                      </v-btn>
                    </div>  
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

    </v-row>
  

    <div v-for="(subscription, index) in get('user').subscriptions" :key="index" >

      <v-row class="mt-8">
      <v-col cols="12">
        <div class="title font-weigth-black">Deine Subscriptions</div>
        <v-card class="pa-2 mt-2 animated-gradient-dBlue-red-radial">
          <v-card-subtitle>
            <div class="d-flex justify-space-between align-center white--text">
              <div>
                <p class="body-1">Aktueller Plan</p>
                <p class="font-weight-bold headline ma-0 pa-0"> {{ subscription.plan.name }}</p>
              </div>
              <div>
                <p class="body-1">Inhalte</p>
                <p class="font-weight-bold headline ma-0 pa-0">{{ subscription.plan.description }}</p>
              </div>
              <div>
                <p class="body-1">Preis pro Monat</p>
                <p class="font-weight-bold headline ma-0 pa-0"> {{ subscription.plan.price }} €</p>
              </div>
              <div class="d-flex align-end justify-end">
                <!-- <v-btn color="primary">Upgraden</v-btn>                 -->
                
              <subscription
                :subscription.sync="subscription"
                :index.sync="index"
              ></subscription>
              </div>
            </div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-8">
       <v-col cols="12">
        <div class="subtitle-1 font-weight-medium">Zahlungsdetails</div>

        <div v-if="get('subscription').payment">
          <v-card outlined class="pa-6 mt-2">
                  
              <v-row align="center">
                <v-col cols="6">
                  <div class="body-1">Begonnen am:</div>
                </v-col>
                <v-col cols="4">
                  <div class="subtitle-1 font-weight-bold">{{ formDate(subscription.created, 'DD-MM-YYY') }}</div>
                  
                </v-col>
                <v-col cols="2">
                  
                </v-col>  
              </v-row>
    
              <v-divider class="my-4"></v-divider>
    
              <v-row align="center">
                <v-col cols="6">
                  <div class="body-1">Vertragsstatus:</div>
                </v-col>
                <v-col cols="4">
                  <div class="subtitle-1 font-weight-bold">
                    <div v-if="get('payment')[index].status != 'EXPIRED' && get('payment')[index].status != 'SUSPENDED'" class="subtitle-1 font-weight-bold">
                      Aktiv
                    </div>
                    <div v-else class="subtitle-1 font-weight-bold">Gekündigt</div>
                  </div>
                </v-col>
                <v-col cols="2">
                  <!-- <v-btn text>Ändern</v-btn> -->
                </v-col>  
              </v-row>
              <v-divider class="my-4"></v-divider>
    
              <v-row align="center">
                <v-col cols="6">
                  <div class="body-1">Abrechnungszeitraum:</div>
                </v-col>
                <v-col cols="">
                  <div class="subtitle-1 font-weight-bold">{{ subscription.plan.period }}</div>
                </v-col>
              </v-row>   
              <v-divider class="my-4"></v-divider>

              <v-row align="center">
              <v-col cols="6">
                <div class="body-1">Nächste Abrechnung am:</div>
              </v-col>
              <v-col cols="4">
                <div class="subtitle-1 font-weight-bold">{{ add30Days(subscription.created) }}</div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    </div>

  </div>
</template>

<script>
  import kontoLoeschen from '@/components/modal/kontoLoeschen'
  import userRole from '@/services/userRoleService'
  import { mapGetters } from 'vuex'
  import APIService from '@/services/api.service';
  import AppService from '@/services/app.service';
  import moment from 'moment';

  // Import um "extenden" zu können
  import userDetails from '@/components/profile/userDetails';

  export default {
    name: "userDetails",
    extends: userDetails,

    components: {
      subscription: () => import('../profile/subscription'),
    },

    data(){
      return {
        email: '',
        birthdateMenu: false,
        birthdate: '',
        birthday: '',
        street: '',
        zipCode: '',
        city: '',
        activePicker: null,
        password: '',
        cancelBtn: true,
        suspendLoader: true,
        subs: '',
        product: '',
        profileImg:              [],
        fileRecordsForUpload:    [],
        certificates:            [],
        profil_tabs: null,
        userNameDialog: false,
        userMailDialog: false,
        userPWDialog: false,
        userBirthDayDialog: false,
        userAddressDialog: false,
        userCountryDialog: false,
        userPLZDialog: false,
        userTownDialog: false,
        showPwIcon: false,
        descriptionVorschau: false,
        valid: {
          email: false,
          password: false,
          name: false,
          birthdate: false,
          address: false,
          country: false,
          zip: false,
          town: false,

        },
        eMailRules: [
          v => !!v || 'Die E-Mailadresse ist erforderlich.',
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Falsches Format für eine E-Mailadresse'
        ],
        passwordRules: [
          v => !!v || 'Bitte Passwort eingeben',
          v => (v && v.length >= 6) || 'Das Passwort ist zu kurz'
        ],
        userNameRules: [
					v => !!v || 'Bitte Namen eingeben',
					v => (v && v.length <= 30) || 'Der Name darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
        addressRules: [
          v => !!v || 'Bitte Adresse eingeben',
          v => /^[a-zA-ZäöüÄÖÜß\s]+\s?\d+[a-zA-Z]?$/.test(v) || 'Falsches Format für eine deutsche Adresse'
        ],

        birthdateRules: [
          v => !!v || 'Bitte Geburtsdatum eingeben',
          v => /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(v) || 'Falsches Format für ein Geburtsdatum'
        ],

        zipRules: [
					v => !!v || 'Preis darf nicht leer sein',
					v => !isNaN(parseFloat(v)) && !isNaN(v) || 'Preis darf nur Zahlen und Komma enthalten',
					v => parseFloat(v) >= 0 || 'Preis muss 0 oder positiv sein'
				],
        content:  "<h1>Initialer Kontent<h1>" ,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "code-block"]
        ],
      }
    },

    computed: {
      ...mapGetters(["get"]),
      oneUser:{
        get(){
          return this.$store.state.main.user.result
        },
        set(val){
          this.$emit("input", val)
        }
      },
      authUser:{
        get(){
          if ( typeof this.$store.state.main.auth_user.result !== "undefined"  ) return this.$store.state.main.auth_user.result
        },
        set(val){
          this.$emit("input", val)
        }
      },
      // isSubscription:{
      //     get(){
      //       if ( typeof this.$store.state.main.products.result !== "undefined"  ) return subscription;
      //     },
      //     set(val){
      //       this.$emit("input", val)
      //     }
      // }
    },

    watch: {
      birthdateMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },

    methods: {
      isTester(){
        return AppService.isTester()
      },
      isCreatorSubActive(){
        return AppService.isCreatorSubActive()
      },

      isConsumerSubActive(){
        return AppService.isConsumerSubActive()
      },

      add30Days(startDate) {
        return AppService.add30Days(startDate)
      },

      upgradePlan(name) {
        this.$store.dispatch('fetch', { endpoint: `/api/v1/products`, host: 'getWebshopHost', state: 'products'})
        this.$store.dispatch('fetch', { endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`, host: 'getWebshopHost', state: 'product'})
        this.$store.dispatch('modal/open', name)
      },

      sevenDaysOnTop(date){
        // Startdatum als String
        const startDate = date
        // Startdatum in Date-Objekt konvertieren
        const startDateObj = new Date(startDate);
        // 31 Tage hinzufügen
        startDateObj.setDate(startDateObj.getDate() + 7);
        // Enddatum in String konvertieren
        const endDate = startDateObj.toISOString().split("T")[0];
        // console.log(endDate);
        return this.formDate(endDate, 'DD.MM.YYYY');
      },

      daysLeft(startDate, timeRange) {
        // Startdatum in Date-Objekt konvertieren
        const startDateObj = new Date(startDate);

        // Heutiges Datum
        const today = new Date();
        // Anzahl der verbleibenden Tage berechnen
        const daysLeft = Math.ceil((today - startDateObj) / (1000 * 60 * 60 * 24));

        // Prüfen, ob der Zeitraum innerhalb des angegebenen Zeitraums liegt
        if (daysLeft < timeRange) {
          var dayCount = (100/timeRange) * daysLeft
          console.log("innerhalb timeRange auf Userdetails", timeRange);
          console.log("innerhalb dayCount auf Userdetails", dayCount);
          return dayCount;
        } else {
          console.log("ausserhalb daysLeft auf Userdetails", daysLeft);
          return 100
        }

      },

      editWebShopUser(object){
        this.$store.dispatch('put', {
          endpoint: `/api/v1/users/${this.$store.state.main.webshop_user.result.id}`,
          event: object,
          host: 'getWebshopHost',
          message: false
        }).then(() => {
          this.$store.dispatch('fetch', 
            { 
              endpoint: `/api/v1/users/me`, 
              host: 'getWebshopHost',
              state: 'webshop_user'
            }
          )
          APIService.setResponse({message: "Änderung gespeichert.", color: "green"})
        })
      },

      editUserProfile(object){

        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.oneUser.id}`,
          event: object,
          message: false
        }).then(() => {
          APIService.setResponse({message: "Änderung gespeichert.", color: "green"});
          this.$store.dispatch('fetch', 
            { 
              endpoint: `/api/v1/users/${this.oneUser.id}`, 
              state: 'user'
            }
          )
        })
      },

      editUserMail(id, email){
        if (this.authUser.email.length > 0) {
          this.$store.dispatch('patch',
          {
            endpoint: `/api/v1/users/${this.authUser.id}`,
            event: { name: this.authUser.email},
            host: 'getAuthHost',
            message: false
          }).then(() => {
            APIService.setResponse({message: "Deine neue E-Mail ist gespeichert.", color: "green"});
          })
        }
        else{
          APIService.setResponse({ message: "Keine Änderung vorgenommen.", color: "blue"})
        }
      },

      editUserPwd(){
        let payload = { email: this.authUser.email}

        // --------------------->
        // DAS MUSS GEÄNDERT WERDEN!!!
        // --------------------->
        if(this.password.length > 0){
          payload = { password: this.password, email: this.authUser.email}
          this.$store.dispatch('patch',
          {
            endpoint: `/api/v1/users/${this.authUser.id}`,
            event: payload,
            host: 'getAuthHost',
            message: false
          }).then(() => {
            APIService.setResponse({ message: "Das Passwort wurde geändert.", color: "green"})
          })
        }
        else{
          APIService.setResponse({ message: "Keine Änderung vorgenommen.", color: "blue"})
        }
        // <---------------------


      },

      linkTo(route) {
        this.$router.push('/' + route)
      },

      // linkTo(adress){
      //   window.open(adress, "_blank")
      //   // window.location.href = adress
      // },

      openWarning(name){
        this.$store.dispatch("modal/open", name)
      },

      saveSocialMedia(e){
        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
          event: e
        })

      },

      currentUser(roles) {
        return userRole.hasRole(roles);
      },

      formatCurrency(number){
        return new Intl.NumberFormat('de-DE',
        {
          style: 'currency',
          currency: 'EUR'
        })
        .format(number)
      },

      formDate(value, format){
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY')
        }
      },

      imgaSource(oneUser){
        AppService.imageSource(oneUser, 100, 100)
      },

      imgSource(oneUser){
        let src = './assets/logo/LogoMark_blue_new.png';
        if( oneUser.image != null){
          src = APIService.getApiHost() + `/${oneUser.image}` ;
        }
        return src;
      },

      onBeforeDeleteImage: function (fileRecord) {
        var i = this.fileRecordsForUpload.indexOf(fileRecord);
        if (i !== -1) {
          this.fileRecordsForUpload.splice(i, 1);
        } else {
          if (confirm('Das Bild wirklich löschen?')) {
            this.$refs.logo.deleteFileRecord(fileRecord); // will trigger 'delete' event
          }
        }
      },

      save(){
        let payload = { email: this.authUser.email}
        if(this.password.length > 0){
          payload = { password: this.password, email: this.authUser.email}
        }

        this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/users/${this.authUser.id}`,
          event: payload,
          host: 'getAuthHost'
        })

        // Namensänderung
        if (this.oneUser.name.length > 0) {
          this.$store.dispatch('patch',
          {
            endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
            event: { name: this.oneUser.name},
            message: false
          })
          .then((response) =>{
            APIService.setResponse({ message: `Änderung erfolgreich`, color: "green" });
            APIService.setUser(response.result);
          });

        }
        this.$store.dispatch("modal/close", 'profil');
      },

      formDate(value, format){
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY')
        }
      },

      close(name) {
        this.$store.dispatch("modal/close", name)
      },

      profilBildUpload() {

          this.$store.dispatch('uploadFiles',
          {
            endpoint: `/api/v1/users/upload`,
            files: {image: this.profileImg},
            metadata: {},
            message: false
          }).then(() => {
            this.$store.dispatch('fetch', {
              endpoint: `/api/v1/users/${this.oneUser.id}`,
              state: 'user'
            })
            APIService.setResponse({ message: `Dein neues Profilbild sieht toll aus.`, color: "green" });
          });

      },

      resetForm(){
        this.image = []
        this.logo = []
        this.fileRecordsForUpload = []
      },

      imgUpload() {
        setTimeout(() => {

          this.$store.dispatch('uploadFiles',
          {
            endpoint: `/api/v1/files`,
            files: {file: this.certificates},
            message: false
          }).then(() => {
            APIService.setResponse({ message: `Upload erfolgreich.`, color: "green" });
          });
        }, 50);
      },
    },
    created() {

      this.$store.dispatch('fetch', { endpoint: `/api/v1/plans`, state: 'plans'})
      // this.$store.dispatch('fetch', {
      //   endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
      //   header: false,
      //   state: 'product',
      //   host: 'getWebshopHost'
      // })

      // this.$store.dispatch('fetch', {
      //     endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
      //     state: 'product',
      //     host: 'getWebshopHost'
      //   })
  

    }
}


</script>
